<template>
    <div>
        <slot />
    </div>
</template>

<script>
    export default {
        name: 'LayoutEmpty',
    }
</script>


<style lang="scss">
</style>